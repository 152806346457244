import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { createRecord } from '@config/functions/requests';
import { computeUrls } from '@config/routes';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import LinearProgress from '@mui/material/LinearProgress';
import { v4 as uuidv4 } from 'uuid';
import BlockStepsList from './BlockStepsList';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { generateMicroserviceUrls, blockGroupsUrls } = computeUrls;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const headingStyles = {
  fontSize: '1.3rem',
  lineHeight: '24px',
  color: '#424242',
  fontWeight: '500',
  mb: 2,
};

export default function GeneratePopup({
  open,
  setOpen,
  microserviceName,
  microserviceId,
  user,
}) {
  const [state, setState] = React.useState({
    phase: 'confirm',
    instanceId: null,
    payload: {},
  });
  const [statusUpdates, setStatusUpdates] = React.useState({});

  const { data: blockGroups, isError } = useRQuery({
    key: ['block-steps', microserviceId, user.token, user?.actAs],
    url: blockGroupsUrls.list(),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
    renderRow: (row) => ({
      id: row?.id,
      order: row?.order,
      label: row?.name,
      blocks: Array.isArray(row?.blocks)
        ? row.blocks.filter((row) => !row?.deleted)
        : [],
    }),
  });

  const allBlocks = React.useMemo(() => {
    return (blockGroups ?? []).reduce((acc, group) => {
      // Ensure that group.blocks is an array before attempting to concatenate it
      if (Array.isArray(group?.blocks)) {
        // Concatenate the current group's blocks with the accumulator
        return acc.concat(group.blocks);
      }
      // If group.blocks is not an array, just return the accumulator as is
      return acc;
    }, []); // Start with an empty array as the initial value of the accumulator
  }, [blockGroups]); // Dependencies array, useMemo will only recompute if blockGroups changes

  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  const handleConfirm = React.useCallback(async () => {
    setState((s) => ({ ...s, phase: 'loading' }));
    try {
      const instanceId = uuidv4();
      const { data } = await createRecord({
        values: { instanceId, microserviceId },
        url: generateMicroserviceUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });
      setState({ phase: 'success', instanceId, payload: data });
    } catch (error) {
      console.error(error);
      setState((s) => ({ ...s, phase: 'error' }));
    }
  }, [microserviceId, user]);

  const generateDetailItems = (payload) => [
    { label: 'Duration', value: `${payload.duration}ms` },
    { label: 'API Git URL', value: payload.apiGitRepoUrl, isLink: true },
    { label: 'Frontend Git URL', value: payload.feGitRepoUrl, isLink: true },
    { label: 'DevOps Git URL', value: payload.devopsGitRepoUrl, isLink: true },
  ];

  return (
    <Dialog open={open} maxWidth='xl' TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {microserviceName} Microservice Generation
          </Typography>
          <Button autoFocus color='inherit' onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 6, height: 'calc(100% - 64px)' }}>
        {state?.phase === 'confirm' && (
          <Box>
            <Typography sx={headingStyles}>Confirm Generation</Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#616161',
                mb: 2,
              }}
            >
              This will create Git repositories, generate code, and set up
              DevOps. Once begun, it can't be undone.
            </Typography>
            <Button variant='outlined' onClick={handleConfirm}>
              Confirm
            </Button>
          </Box>
        )}

        {state?.phase !== 'confirm' && (
          <BlockStepsList
            user={user}
            instanceId={state?.instanceId}
            microserviceId={microserviceId}
            headingStyles={headingStyles}
            blockGroups={blockGroups}
            isError={isError}
            statusUpdates={statusUpdates}
            setStatusUpdates={setStatusUpdates}
          />
        )}

        {state?.phase === 'loading' ||
        (Object.keys(statusUpdates)?.length < allBlocks?.length &&
          state?.phase === 'success') ? (
          <Box sx={{ mt: 6 }}>
            <LinearProgress />
          </Box>
        ) : null}

        {state?.phase === 'error' && (
          <Alert severity='error' sx={{ mt: 3 }}>
            Failed to generate {microserviceName}. Please check logs for further
            details.
          </Alert>
        )}

        {state?.phase === 'success' &&
        Object.keys(statusUpdates)?.length >= allBlocks?.length ? (
          <Box sx={{ mt: 3 }}>
            <Alert icon={<CheckIcon fontSize='inherit' />} severity='success'>
              {microserviceName} has been generated and will be ready to view in
              10 mins.
            </Alert>

            {generateDetailItems(state.payload).map((item, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', mt: 3 }}
              >
                <Typography
                  sx={{
                    mr: 1,
                    color: '#424242',
                    fontWeight: '500',
                  }}
                >
                  {item?.label}:
                </Typography>
                {item?.isLink ? (
                  <a
                    href={item.value}
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {item?.value}
                  </a>
                ) : (
                  <Typography>{item?.value}</Typography>
                )}
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </Dialog>
  );
}
